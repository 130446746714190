import { FC } from 'react'
import './styles.css'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage, setLanguage, supportedLanguagesForCountryCode } from '../../utils/LanguageUtils'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'
import { APP_VERSION } from '../../config/Config'
import { ContentContainer } from '../ContentContainer/ContentContainer'

const Footer: FC = () => {
  const { t, ready } = useTranslation()
  const currentLanguage = getCurrentLanguage()
  const { homeMarket } = useSelector((state: RootState) => state.userDetails)

  const handleLanguageChange = (language: string) => {
    if (!homeMarket) {
      return
    }
    setLanguage(language, homeMarket)
  }

  const languages = homeMarket ? supportedLanguagesForCountryCode(homeMarket) : []
  const footerItems = t('footer.links', { returnObjects: true }) as unknown as [{ label: string; url: string }]

  return (
    <footer className="footer-component">
      <div className="footer-banner">
        <ContentContainer>
          <ul className="languages-list">
            {languages.map((language) => (
              <li
                key={language}
                className={`language-list-item ${currentLanguage === language ? 'active' : ''}`}
                onClick={() => handleLanguageChange(language)}
              >
                {language.toUpperCase()}
              </li>
            ))}
          </ul>
        </ContentContainer>
      </div>
      <ContentContainer>
        <div className="footer-content">
          <div className="footer-general-info">
            <span>{t('footer.copyright', { year: new Date().getFullYear() })}</span>
            <span>Version {APP_VERSION}</span>
          </div>
          <div className="footer-links">
            {ready &&
              footerItems.map(({ label, url }) => (
                <a key={label} href={url}>
                  {label}
                </a>
              ))}
          </div>
        </div>
      </ContentContainer>
    </footer>
  )
}

export default Footer
