import packageJson from '../../package.json'

export default {
  BASE_URL: import.meta.env.VITE_BACKEND_URL,
  GCDM_CLIENT: import.meta.env.VITE_GCDM_CLIENT,
  GCDM_BASE_URL: import.meta.env.VITE_GCDM_BACKEND_URL,
  GCDM_REDIRECT_URI: import.meta.env.VITE_GCDM_REDIRECT_URI,
  DEBUG_MODE: import.meta.env.VITE_DEBUG === 'true',
}

export const APP_VERSION = packageJson.version
